.tabs {
  @include media-breakpoint-down(md) {
    .container-fluid {
      padding: 0;
    }
  }
  .nav-tabs {
    border: none;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      margin: $space-inset-s;
      flex-direction: row;
    }
    .nav-item {
      flex-grow: 1;
      margin: 0 0 $space-xs 0;
      @include media-breakpoint-up(md) {
        margin: 0;
      }
      &+ .nav-item {
        @include media-breakpoint-up(md) {
          margin: 0 0 0 $space-xs;
        }
      }
      .nav-link {
        background-color: $medium-blue;
        color: $white;
        @include media-breakpoint-up(sm) {
          font-size: 12px;
        }
        @include media-breakpoint-up(md) {
          font-size: 15px;
        }
        border-radius: 0;
        border: none;
        text-transform: uppercase;
        text-align: center;
        &.active, &:focus, &:hover {
          background-color: $master-blue;
          color: $white;
          border: none;
        }
      }
    }
  }
}
