@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('/assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('/assets/fonts/roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 200;
  src: url('/assets/fonts/roboto/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 600;
  src: url('/assets/fonts/roboto/Roboto-Bold.ttf');
}
@font-face {
    font-family: 'IslandAdventuresScript';
    src: url('/assets/fonts/islandadventures-script/KaushanScript-Regular.otf'),
        url('/assets/fonts/islandadventures-script/islandadventures-script.woff2') format('woff2'),
        url('/assets/fonts/islandadventures-script/islandadventures-script.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
