@import
"_variables.scss",
"_typography.scss",
"elements/_buttons.scss";

.align-left {
  text-align: left !important;
}
.align-center {
  text-align: center !important;
}
.align-right {
  text-align: right !important;
}
.align-justify {
  text-align: justify !important;
}

// custom font helpers, also used by rich text editor settings
.font-semibold {
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
}

.font-bold {
  font-family: 'Roboto' !important;
  font-weight: 600;
}

.font-bold-small {
  font-family: 'Roboto' !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 22px !important;
}

.font-bold-medium {
  font-family: 'Roboto' !important;
  font-weight: 600 !important;
  font-size: 45px !important;
  line-height: 47px !important;
}

.font-bold-large {
  font-family: 'Roboto' !important;
  font-weight: 600 !important;
  font-size: 75px !important;
  line-height: 77px !important;
}

.font-main {
  font-family: 'Roboto' !important;
  font-weight: 300 !important;
}

.font-main-small {
  font-family: 'Roboto' !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.font-light {
  font-family: 'Roboto' !important;
  font-weight: 200;
}

.font-cursive {
  font-family: 'IslandAdventuresScript' !important;
}

.font-cursive-large {
  font-family: 'IslandAdventuresScript' !important;
  font-size: 30px !important;
}

.font-cursive-small {
  font-family: 'IslandAdventuresScript' !important;
  font-size: 25px !important;
}

.font-color-black {
  color: $black !important;
  font-family: 'Roboto' !important;
}

.font-red {
  color: $master-red !important;
  font-family: 'Roboto' !important;
}

.font-15 {
  font-size: 15px;
  line-height: 17px;
}

.font-20 {
  font-size: 20px;
  line-height: 22px;
}

.font-25 {
  font-size: 25px;
  line-height: 27px;
}

.font-30 {
  font-size: 30px;
  line-height: 32px;
}

.font-35 {
  font-size: 35px;
  line-height: 37px;
}

.font-40 {
  font-size: 40px;
  line-height: 42px;
}
