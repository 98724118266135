@import "base/_variables.scss";
.monthpicker {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	width: 60%;
}
.monthpicker_selector {
  width: 100%;
	position: absolute;
	top: 100%;
	background-color: $light-blue;
	min-width: 173px;
	z-index: 100;
	*::selection {
		background: transparent;
	}
	> table {
		color: $white;
		width: 100%;
		text-align: center;
		border-spacing: 0;
		border-collapse: collapse;
		tr {
      &:first-child {
        height: 30px;
      }
			td {
				&:first-child {
					border-left: none;
				}
				&:last-child {
					border-right: none;
				}
			}
			&:last-child td {
				border-bottom: none;
			}
		}
	}
	.month {
    padding: $space-xs;
		border: 1px solid $black;
		cursor: pointer;
		&:hover {
			background-color: darken($light-blue,10%);
		}
		&.off {
			color: $master-gray;
			cursor: not-allowed;
			&:hover {
				background: none;
			}
		}
	}
}
.monthpicker_input {
	width: 100%;
	height: 100%;
	border: none;
	@extend .form-control;
	&.active {
		background-color: $light-blue;
		color: $black;
	}
}
.yearSwitch {
	padding: 0 10px;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 3px;
	color: $white;
	font-weight: bold;
	cursor: pointer;
	&.off {
		visibility: hidden;
	}
}
.yearValue {
  font-size: 18px;
  padding: $space-xs;
}
.month.selected {
	background: $light-blue;
	color: $master-red;
	font-weight: bold;
}
