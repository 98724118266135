body {
  background: $bg-color;

  #error{
    .page{
      margin: 30px 0 !important;
    }
  }
}

.site-wrapper {
  background: $white;
  overflow-x: hidden;
  margin: auto;
  max-width: 1400px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;
}

.slogan {
  p {
    font-size: 28px;
    line-height: 32px;
    @include media-breakpoint-up(lg) {
      font-size: 32px;
      line-height: 34px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 40px;
      line-height: 42px;
    }
  }
}

header#site-header {
  #site-logo {
    display: block;
    width: 80%;
    margin:auto;
  }
  .menus {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $space-l;
  }
}

nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0;
      line-height: 20px;
      padding: 0;
    }
  }
  &#lower {
    padding: $space-stack-m;
    a {
      @include main-font;
      color: $black;
      font-size: 14px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }
    ul {
      justify-content: space-between;
      padding-right: $space-s;
    }
  }
  &#desktop {
    margin-left: 3%;
    height: 44px;
    background-size: 100% 44px;
    a {
      @include main-font-light;
      font-size: 14px;
      color: $white;
      @include media-breakpoint-down(md) {
        font-size: 11px;
        margin: 0;
      }
    }
    ul {
      padding: 8px  45px  8px  20px;
      justify-content: space-between;
    }

  }
  &#mobile {
    background-color: $master-blue;
    padding: $space-s 0;
    li {
      cursor: pointer;
    }
    a {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: inherit;
    }
    #nav-toggle {
      width: 37px;
      height: 27px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      margin: 0 $space-m;

      span {
        display: block;
        position: absolute;
        height: 20%;
        width: 100%;
        background: $white;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 40%;
        }
        &:nth-child(3) {
          top: 40%;
        }
        &:nth-child(4) {
          top: 80%;
        }
      }
      &.open span {
        &:nth-child(1) {
          top: 20%;
          width: 0;
          left: 50%;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 18px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}


#content {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  >section.main-content {
    width: 100%;
    max-width: 100%;
    padding-bottom: 40px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
      width: calc(100% - 146px);
      max-width: calc(100% - 146px);
      padding-bottom: 85px;
      margin-left: $space-s;
      margin-right: $space-s;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 115px;
    }
    >section {
      &.banner {
        .cover {
          min-height: 250px;
          @include media-breakpoint-up(lg) {
            min-height: 500px;
          }
          position: relative;
          h1 {
            position: absolute;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 70px;
          }
        }
      }
      margin: $space-m 0 $space-m 0;
      &.with-card {
        margin-bottom: $space-xl;
      }
      .tab-pane {
        &.with-card {
          margin-bottom: $space-xl;
        }
      }
      &.pages {
        .page {
          margin: $space-m 0 $space-m 0;
        }
      }
      &.content {
        margin: $space-stack-m;
        li {
          margin: $space-stack-s;
        }
      }
      &.content.full-width {
        .full-width-title {
          h3 {
            text-align: center;
            margin: $space-stack-l;
            font-size: 30px;
            line-height: 30px;
          }
          &.bottom-border {
            h3 {
              margin: 0;
              line-height: 48px;
              border-bottom: 5px solid $master-red;
            }
          }
        }
        p {
          @include main-font;
        }
      }
      &.page {
        h3 {
          text-align: center;
          font-size: 30px;
        }
        .two-column-titles {
          margin-bottom: $space-s;
          p {
            font-size: 25px;
            line-height: 28px;
          }
          @include media-breakpoint-up(md) {
            h2,p {
              display: inline;
              margin-right: $space-m;
              margin-bottom: $space-s;
            }
          }
          &.bold-centered {
            text-align: center;
            h2 {
              @include main-font-bold;
            }
          }
        }
        p {
          @include main-font;
        }
        .carousel-text {
          h2 {
            @include cursive-font;
          }
          h3 {
            text-align: left;
          }
          h5 {
            @include main-font-semibold;
          }
          table {
            width: 100%;
            td {
              width: 50%;
              text-align: center;
              img {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
  // &.no-scroll{
  //   height: 100vh;
  // }
}

footer#site-footer {
  padding-bottom: $space-xl;
  background: $master-blue;
  color: $white;
  .container-fluid {
    padding: 0 $space-m;
  }
  a, p, small {
    color: $white;
    @include main-font;
    margin: 0;
  }
  nav {
    ul.underline {
      li {
        padding: $space-inset-s;
      }
    }
  }
  .footer-ribbon {
    width: 100%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      display: block;
      top: -35px;
      @include media-breakpoint-up(sm) {
        top: -50px;
      }
      @include media-breakpoint-up(md) {
        top: -85px;
      }
      @include media-breakpoint-up(lg) {
        top: -115px;
      }
    }
  }
  .row {
    padding: 0;
    margin-bottom: $space-l;
    .info {
      padding: 0;
      img {
        display: block;
        height: 80px;
        max-width: 250px;
        margin-bottom: $space-m;
        @include media-breakpoint-up(md) {
          max-width: 280px;
        }
      }
      strong {
        display: block;
        line-height: 24px;
      }
      .location {
        margin: $space-stack-s;
        strong {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  .mobile-narrow {
    padding: $space-inset-m;
    @include media-breakpoint-down(sm) {
      padding: $space-inset-l;
    }
  }
}

aside#site-sidebar {
  width: 130px;
  color: $white;
  padding-bottom: 40px;
  @include media-breakpoint-up(md) {
    padding-bottom: 85px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 115px;
  }
  background-image: linear-gradient(to bottom,
  $master-blue 0px,
  $master-blue 2600px,
  #88929c 100%
  );
  background-position: 0px 0px;
  background-repeat:   no-repeat;
  .section-title {
    transform: translateX(-$space-m);
    margin-top: $space-s + $space-xs;
    width: calc(105% + #{$space-m});
    background-color: $white;
    color: $master-blue;
    padding: $space-s;
    padding-left: $space-m + $space-s;
    box-shadow: $light-shadow;
    h2 {
      @include main-font;
      color: $master-blue;
      font-size: 17px;
      line-height: 21px;
      margin: 0;
    }
  }
  section {
    padding-bottom: $space-l;
    &#our-vessels .sub-section a {
      font-size: 14px;
    }
    &#whales-and-wildlife, &#details-and-information {
      .sub-section a {
        @include main-font-light;
      }
    }
    .sub-section {
      margin: $space-m $space-s;
      a {
        @include main-font-bold;
        color: $white;
        cursor: pointer;
      }
      p {
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        display: block;
        color: $white;
        margin: $space-stack-xs;
        @include main-font-light;
      }
      strong, a {
        font-size: 15px;
      }
      .tertiary-link {
        font-size: 14px;
        line-height: 20px;
        margin: $space-stack-s;
      }
    }
  }
}

.socials {
  padding: $space-inset-m;
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-up(md) {
    margin-top: $space-xl*2;
    margin-bottom: $space-xl*2;
    min-height: 450px;
    flex-direction: column;
    align-items: center;
  }
  a {
    .fa {
      color: rgba($white,0.5);
      &:visited,&:focus {
        color: rgba($white,0.5);
      }
      &:hover {
        color: $white;
      }
    }
  }
}

.bg-blue {
  background: $master-blue;
}
.bg-lighter-grey {
  background: $lighter-grey;
}
.text-blue {
  color: $master-blue !important;
  p {
    color: $master-blue !important;
  }
}
.text-white {
  color: $white !important;
  p {
    color: $white !important;
  }
}
.card {
  padding: $space-m;
  z-index: 2;
  box-shadow: $light-shadow;
  margin-bottom: -$space-xl;
}

.staff {
  h3 {
    text-align: center;
    margin: $space-stack-l;
  }
  .staff-container {
    display: flex;
    align-items: center;
    margin-bottom: $space-l;
    .staff-image {
      width: 40%;
    }
    .staff-description {
      margin-left: $space-s;
      width: 55%;
      p {
        margin: $space-stack-s;
      }
    }
  }
}

.asset-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    position: relative;
    flex-direction: row;
    height: 500px;
  }
  .text {
    margin-top: 0;
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 22px;
      line-height: 22px;
    }
  }
}

.text {
  color: $white;
  margin-left: $space-m;
  margin-top: $space-l;
  h2 {
    @include main-font-bold;
    font-size: 60px;
    text-transform: uppercase;
  }
  h3 p {
    margin: 0;
    padding-top: $space-m;
    color: $white;
    font-size: 25px;
  }
  p {
    @include main-font;
    color: $white;
    max-width: 222px;
    font-size: 15px;
  }
}
.stamps {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
    position: absolute;
    bottom: $space-m;
    right: 0;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .stamp {
    margin-right: $space-m;
  }
}
a.noformat:hover, a.noformat:focus, a.noformat:active {
  text-decoration: none;
  color: inherit;
}

a.message-banner {
  background-color: $master-red;
  display: none;
  align-items: center;
  justify-content: center;
  height: 45px;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-decoration: none;
  }
  &.closed {
    height: 0;
    transition: all 0.2s ease-in-out;
  }
  .close {
    position: absolute;
    color: $white;
    right: $space-m;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
    color: $white !important;
  }
}

iframe[name=google_conversion_frame] {
  height: 0;
  display: none;
}
