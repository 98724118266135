.thumbnails {
  .row {
    margin: 0;
    .col-xs-12 {
      padding: 0;
    }
  }
  a:hover {
    text-decoration: none;
  }
  .page-thumbnail {
    min-height: 200px;
    display: flex;
    padding: $space-s;
    margin: $space-s;
    background-color: $master-gray;
    h3 {
      margin: auto auto 0 0;
      padding: $space-xs $space-s;
      background-color: rgba($master-gray,0.7);
    }
  }
}
